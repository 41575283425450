<script>
  import Base from '@backend/Base.vue';
  import BOGen from '@helper/BOGen';
  import Gen from '@helper/Gen';
  import draggable from 'vuedraggable'

  export default {
    name: "WaterProduct",
    extends: Base,
    components: {
      draggable,
    },
    data() {
      return {
        Name: "WaterProduct",
        hero: {},
        mrProd: {},
        mrBody: {},
        mrScale: {},
        mrCountry: {},
        mrRegion: {},
        mrVarietals: {},
        type: {},
        row2: {},
        font_style: {},
        font_size: {},
        sub_font_size: {},
        brand: {},
        product: {},
        row: {
          apd_mc_id: '',
          text_brand: '',
          text_body: '',
          text_scale: '',
          text_country: ''
        }
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData()
    },
    watch: {
      '$route.query'() {
        this.refreshData()
      },
      'filter.country'(v) {
        if (!v) return
        this.search()
      },
      'filter.sort'(v) {
        if (!v) return
        this.search()
      },
      'filter.product'(v) {
        if (!v) return
        this.search()
      },
    },
    methods: {
      changeBrand(e) {
        this.row.text_brand = e.added.text
      },
      changeBody(e) {
        this.row.text_body = e.added.text
      },
      changeScale(e) {
        this.row.text_scale = e.added.text
      },
      changeCountry(e) {
        this.row.text_country = e.added.text
      },
      preview() {
        $('#preview_product').modal();
      },
      endDrag() {
        BOGen.apirest('/' + this.Name, {
          data: this.data.data,
          type: 'sort'
        }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
      submitHero() {
        BOGen.apirest('/' + this.Name, {
          data: this.hero,
          type: 'updateHero'
        }, (err, resp) => {
          if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.hero-info')
          if (resp.success) {
            Gen.info(resp.message, resp.style, 2000, '.hero-info')
            this.refreshData()
            setTimeout(() => {
              $('[href="#collapseExample"]').click()
            }, 2100)
          }
        }, "POST");
      },
      addRegion() {
        this.row2.type = 'addRegion'
        BOGen.apirest('/' + this.Name, this.row2, (err, resp) => {
          if (resp.error == 1) {
            return Gen.info(resp.message, "danger", 3000, '.modal-info')
          } else {
            return Gen.info(resp.message, "success", 1000, '.modal-info').then(() => {
              $('#add_region').modal('hide')
              this.$set(this.row2, 'mr_name_en', '')
              this.$set(this.row2, 'mr_name_id', '')
              setTimeout(() => {
                this.mrRegion = resp.mrRegion
              }, 300)
              setTimeout(() => {
                this.row.apd_mr_id = resp.selected
              }, 500)

            })

          }
        }, "POST");
      },
      addItem() {
        this.row2.type = 'addItem'
        BOGen.apirest('/' + this.Name, this.row2, (err, resp) => {
          if (resp.error == 1) {
            return Gen.info(resp.message, "danger", 3000, '.modal-info')
          } else {
            return Gen.info(resp.message, "success", 1000, '.modal-info').then(() => {
              $('#add_item').modal('hide')
              setTimeout(() => {
                this.row.mpn_cat_id = resp.cat
                this.inputCat.mpc_parent_id = resp.cat
              }, 300)
              this.mrProd = resp.mrProd
            })

          }
        }, "POST");
      },
      addVarietals() {
        this.row2.type = 'addVarietals'
        BOGen.apirest('/' + this.Name, this.row2, (err, resp) => {
          if (resp.error == 1) {
            return Gen.info(resp.message, "danger", 3000, '.modal-info')
          } else {
            return Gen.info(resp.message, "success", 1000, '.modal-info').then(() => {
              $('#add_varietals').modal('hide')
              this.$set(this.row2, 'mpv_name_en', '')
              this.$set(this.row2, 'mpv_name_id', '')
              setTimeout(() => {
                this.mrVarietals = resp.mrVarietals
              }, 300)

            })

          }
        }, "POST");
      },
      addBody() {
        this.row2.type = 'addBody'
        BOGen.apirest('/' + this.Name, this.row2, (err, resp) => {
          if (resp.error == 1) {
            return Gen.info(resp.message, "danger", 3000, '.modal-info')
          } else {
            return Gen.info(resp.message, "success", 1000, '.modal-info').then(() => {
              $('#add_body').modal('hide')
              this.$set(this.row2, 'mbps_name_en', '')
              this.$set(this.row2, 'mbps_name_id', '')
              setTimeout(() => {
                this.mrBody = resp.mrBody
              }, 300)

            })
          }
        }, "POST");
      },
      addScale() {
        this.row2.type = 'addScale'
        BOGen.apirest('/' + this.Name, this.row2, (err, resp) => {
          if (resp.error == 1) {
            return Gen.info(resp.message, "danger", 3000, '.modal-info')
          } else {
            return Gen.info(resp.message, "success", 1000, '.modal-info').then(() => {
              $('#add_scale').modal('hide')
              this.$set(this.row2, 'mds_name_en', '')
              this.$set(this.row2, 'mds_name_id', '')
              setTimeout(() => {
                this.mrScale = resp.mrScale
              }, 300)

            })

          }
        }, "POST");
      },
      // addScale(){
      //   this.row2.type='addScale'
      //   BOGen.apirest('/'+this.Name, this.row2, (err,resp)=>{
      //     if(resp.error==1){
      //       return Gen.info(resp.message, "danger",3000,'.modal-info')
      //     } else {
      //       return Gen.info(resp.message, "success", 1000,'.modal-info').then(()=>{
      //         $('#add_scale').modal('hide')
      //         setTimeout(()=>{
      //           this.mrScale = resp.mrScale
      //         },300)

      //      })

      //     }            
      //   }, "POST");
      // },
      addCounry() {
        this.row2.type = 'addCountry'
        BOGen.apirest('/' + this.Name, this.row2, (err, resp) => {
          if (resp.error == 1) {
            return Gen.info(resp.message, "danger", 3000, '.modal-info')
          } else {
            return Gen.info(resp.message, "success", 1000, '.modal-info').then(() => {
              $('#add_country').modal('hide')
              this.$set(this.row2, 'mc_name_en', '')
              this.$set(this.row2, 'mc_name_id', '')
              this.$set(this.row2, 'mc_capital_city_en', '')
              this.$set(this.row2, 'mc_capital_city_id', '')
              setTimeout(() => {
                this.mrCountry = resp.mrCountry
              }, 300)
              setTimeout(() => {
                this.row.apd_mc_id = resp.selected
              }, 500)

            })

          }
        }, "POST");
      },
    }
  };
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" v-if="!$route.params.id">
        <div class="card">
          <VForm @resp="submitHero" method="post">
            <div class="card-body">
              <h5 class="card-title">Hero Image</h5>
              <div class="row">
                <div class="col-md-6">
                  <div class="wrap_slider_img">
                    <img :src="uploader(hero.ash_image_desktop)" class="img-responsive" />
                    <div class="slider_name">
                      <p>Hero Image</p>
                    </div>
                    <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
                      aria-controls="collapseExample" class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
                  </div>
                </div>
              </div>
              <div class="row collapse mt-4" id="collapseExample">
                <div class="hero-info col-12"></div>
                <div class="col-md-4 mb-3">
                  <BoField name="ash_image_desktop" mandatory>
                    <Uploader :param="{thumbnail:true}" name="ash_image_desktop" v-model="hero.ash_image_desktop"
                      type="hero_descktop" uploadType="cropping"></Uploader>
                  </BoField>
                  <BoField name="ash_image_mobile" mandatory>
                    <Uploader :param="{thumbnail:true}" name="ash_image_mobile" v-model="hero.ash_image_mobile"
                      type="hero_mobile" uploadType="cropping"></Uploader>
                  </BoField>
                </div>
                <div class="col-md-8 mb-3">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="panel_group">
                        <div class="panel_heading">Heading</div>
                        <div class="panel_body">
                          <div class="row">
                            <div class="col-md-9">
                              <BoField name="ash_title_en" v-model="hero.ash_title_en"></BoField>
                            </div>
                            <div class="col-md-9">
                              <BoField name="ash_title_id" v-model="hero.ash_title_id"></BoField>
                            </div>
                            <div class="col-lg-4 col-md-5">
                              <div class="form-group">
                                <label class="control-label">Font Style</label>
                                <select class="form-control" v-model="hero.ash_title_font_type">
                                  <option v-for="(v,k) in font_style" :key="k" :value="k">{{v}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-4">
                              <div class="form-group">
                                <label class="control-label">Font Size </label>
                                <select class="form-control" v-model="hero.ash_title_size">
                                  <option v-for="(v,k) in font_size" :key="k" :value="k">{{v}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-3">
                              <BoField :attr="{type:'color'}" name="ash_title_color" v-model="hero.ash_title_color">
                              </BoField>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="panel_group">
                            <div class="panel_heading">Sub-Heading</div>
                            <div class="panel_body">
                              <div class="row">
                                <div class="col-md-9">
                                  <div class="form-group">
                                    <BoField name="ash_sub_title_en" v-model="hero.ash_sub_title_en"></BoField>
                                  </div>
                                </div>
                                <div class="col-md-9">
                                  <BoField name="ash_sub_title_id" v-model="hero.ash_sub_title_id"></BoField>
                                </div>
                                <div class="col-lg-4 col-md-5">
                                  <div class="form-group">
                                    <label class="control-label">Font Style</label>
                                    <select class="form-control" v-model="hero.ash_sub_title_font_type">
                                      <option v-for="(v,k) in font_style" :key="k" :value="k">{{v}}</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-lg-2 col-md-4">
                                  <div class="form-group">
                                    <label class="control-label">Font Size </label>
                                    <select class="form-control" v-model="hero.ash_sub_title_size">
                                      <option v-for="(v,k) in sub_font_size" :key="k" :value="k">{{v}}</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-lg-2 col-md-3">
                                  <div class="form-group">
                                    <label class="control-label">Color </label>
                                    <input class="form-control" type="color" id="favcolor" name="favcolor"
                                      v-model="hero.ash_sub_title_color">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-6 mt-3">
                      <button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </VForm>
        </div>
      </div>

      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" v-if="!$route.params.id">
        <div class="card">
          <div class="card-body">
            <VForm @resp="search">
              <div class="row justify-content-end">
                <div class="col-md-2">
                  <h5 class="card-title">Filter</h5>
                </div>
                <div class="col-md-10 col-lg-6">
                  <div class="row justify-content-end">
                    <div class="col-md-4">
                      <div class="form-group mb-lg-0">
                        <select2 :options="{asc:'A-Z',desc:'Z-A'}" v-model="filter.sort">
                          <option value="">-- Sort by --</option>
                        </select2>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="form-row">
                    <div class="col-9">
                      <div class="form-group mb-0">
                        <div class="input-group">
                          <input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
                            placeholder="Search...">
                          <div class="input-group-append">
                            <button class="btn btn-info" type="button" @click="search()"><i
                                class="fas fa-search"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <router-link :to="{name:Name}" class="btn btn-warning btn-block">Reset</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" v-if="!$route.params.id">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ObjectName}} List</h5>
            <div class="row">
              <div class="col-lg-3 col-md-6" v-for="(v,k) in data.data" :key="k">
                <div class="card">
                  <div class="card-body">
                    <div class="product-img">
                      <img :src="uploader(v.apd_image)">
                      <div class="pro-img-overlay">
                        <router-link role="button" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"
                          class="bg-info"><i class="fas fa-pencil-alt"></i></router-link>
                        <a href="javascript:void(0)" class="bg-danger" @click="deleteItem($event,k)"
                          v-tooltip="'Remove'"><i class="ti-trash"></i></a>
                      </div>
                      <span class="label label-success" v-if="v.apd_is_active == 'Y'">Active</span>
                      <span class="label label-danger" v-else>Inactive</span>
                    </div>
                    <div class="product-text">
                      <h5 class="card-title m-b-0">{{v.apd_name}}</h5>
                      <small class="text-muted db">{{v.apd_year}}</small>
                      <h6 class="card-title m-b-0">{{v.price}}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-body">
            <div class="row" v-if="NotFound">
              <div class="col-12 text-center" style="padding: 20px;">
                <h3 class="tc">{{NotFound}}</h3>
              </div>
            </div>
            <div class="row" v-if="data.data===false">
              <div class="col-12 text-center">
                <LoadingSpinner light></LoadingSpinner>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <Pagination class="float-right" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Form -->
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" v-if="$route.params.id">
        <div class="card">
          <VForm @resp="submitForm" method="post">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-8">
                  <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
                </div>
              </div>
              <div class="info"></div>
              <div class="form-body">
                <div class="row">
                  <div class="col-md-3">
                    <BoField name="apd_image">
                      <Uploader name="apd_image" type="product" v-bind="validation('apd_image')" uploadType="cropping"
                        :param="{thumbnail:true}" v-model="row.apd_image"></Uploader>
                    </BoField>
                  </div>
                  <div class="col-md-9 mt-3">
                    <div class="row">
                      <div class="col-md-6">
                        <BoField name="apd_brand_id" :label="'Brand'" :attr="{required:'required'}">
                          <select2 required="required" name="apd_brand_id" v-bind="validation('apd_brand_id')"
                            :options="brand" :object="['mb_id','mb_name']" v-model="row.apd_brand_id">
                            <option value="">-- Select Brand --</option>
                          </select2>
                        </BoField>
                      </div>
                      <div class="col-md-6">
                        <BoField name="apd_name" mandatory
                          :attr="{placeholder:'e.g. PINOT GRIGIO 2016',required:'required',minlength:3,maxlength:100}"
                          v-model="row.apd_name"></BoField>
                      </div>
                      <div class="col-md-5">
                        <BoField name="apd_year" v-model="row.apd_year"
                          :attr="{placeholder:'e.g. 2006',maxlength:4,minlength:4,type:'number'}"></BoField>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 class="card-title m-t-40">Product Details</h5>
                <hr>
                <div class="row">
                  <div class="col-md-6">
                    <BoField name="apd_grape" :label="'Varietals'">
                      <div class="input-group">
                        <select2 name="apd_grape" v-bind="validation('apd_grape')" :options="mrVarietals"
                          :object="['mpv_id','mpv_name_en']" v-model="row.apd_grape">
                          <option value="">--Select Varietals--</option>
                        </select2>
                        <div class="input-group-append">
                          <button data-toggle="modal" data-target="#add_varietals" class="btn btn-secondary"
                            type="button" onclick="education_fields();"><i class="fas fa-plus"></i></button>
                        </div>
                      </div>
                    </BoField>

                    <BoField name="apd_avb">
                      <div class="input-group">
                        <input name="apd_avb" type="number" class="form-control" v-model="row.apd_avb"
                          placeholder="e.g. 13.0">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">%</span>
                        </div>
                      </div>
                    </BoField>

                    <BoField name="apd_mpbs_id">
                      <div class="input-group">
                        <select2 name="apd_mpbs_id" v-bind="validation('apd_mpbs_id')" :options="mrBody"
                          :object="['mbps_id','mbps_name_en']" v-model="row.apd_mpbs_id">
                          <option value="">-- Filter By Texture --</option>
                        </select2>
                        <div class="input-group-append">
                          <button data-toggle="modal" data-target="#add_body" class="btn btn-secondary" type="button"
                            onclick="education_fields();"><i class="fas fa-plus"></i></button>
                        </div>
                      </div>
                    </BoField>

                    <BoField name="apd_mds_id">
                      <div class="input-group">
                        <select2 name="apd_mds_id" v-bind="validation('apd_mds_id')" :options="mrScale"
                          :object="['mds_id','mds_name_en']" v-model="row.apd_mds_id">
                          <option value="">-- Filter By Scale --</option>
                        </select2>
                        <div class="input-group-append">
                          <button data-toggle="modal" data-target="#add_scale" class="btn btn-secondary" type="button"
                            onclick="education_fields();"><i class="fas fa-plus"></i></button>
                        </div>
                      </div>
                    </BoField>

                    <BoField name="apd_note" v-model="row.apd_note" :attr="{maxlength:254}">
                      <textarea v-model="row.apd_note" name="" id="" class="form-control"></textarea>
                    </BoField>
                    <BoField name="apd_pairing" v-model="row.apd_pairing" :attr="{maxlength:254}">
                      <textarea v-model="row.apd_pairing" name="" id="" class="form-control"></textarea>
                    </BoField>

                    <BoField name="apd_mc_id">
                      <div class="input-group">
                        <select2 name="apd_mc_id" :options="mrCountry" :object="['mc_id','mc_name_en']"
                          v-model="row.apd_mc_id">
                          <option value="">-- Filter By Country --</option>
                        </select2>
                        <div class="input-group-append">
                          <button data-toggle="modal" data-target="#add_country" class="btn btn-secondary" type="button"
                            onclick="education_fields();"><i class="fas fa-plus"></i></button>
                        </div>
                      </div>
                    </BoField>

                    <BoField name="apd_mr_id" :label="'Region'">
                      <div class="input-group">
                        <select2 name="apd_mr_id" v-bind="validation('apd_mr_id')" :options="mrRegion"
                          :object="['mr_id','mr_name_en']" v-model="row.apd_mr_id">
                          <option value="">--Select Region--</option>
                        </select2>
                        <div class="input-group-append">
                          <button data-toggle="modal" data-target="#add_region" class="btn btn-secondary" type="button"
                            onclick="education_fields();"><i class="fas fa-plus"></i></button>
                        </div>
                      </div>
                    </BoField>

                    <BoField name="apd_price">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">IDR</span>
                        </div>
                        <InputCurrency name="apd_price" class="form-control" v-model="row.apd_price"></InputCurrency>
                      </div>
                      <label :error="'apd_price'" :label="'Price'"></label>
                    </BoField>

                    <BoField name="apd_how_to_bay">
                      <div class="row">
                        <div class="col-6">
                          <div class="row">
                            <div class="col-5">
                              <radio name="apd_how_to_bay" v-model="row.apd_how_to_bay" option="W"
                                :attr="validation('apd_how_to_bay')">Whatsapp</radio>
                            </div>
                            <div class="col-5">
                              <radio name="apd_how_to_bay" v-model="row.apd_how_to_bay" option="L">Marketplace</radio>
                            </div>
                          </div>
                        </div>
                      </div>
                    </BoField>

                    <BoField name="apd_link" v-if="row.apd_how_to_bay == 'L'">
                      <div class="form-group">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i class="fas fa-link"></i></span>
                          </div>
                          <input type="url" class="form-control" v-model="row.apd_link"
                            placeholder="https://shopee.co.id/Tropica-Melon-(Mixer)" aria-label="price"
                            aria-describedby="basic-addon1">
                        </div>
                      </div>
                    </BoField>

                    <BoField name="apd_is_active">
                      <div class="row">
                        <div class="col-6">
                          <div class="row">
                            <div class="col-4">
                              <radio name="apd_is_active" v-model="row.apd_is_active" option="Y"
                                :attr="validation('apd_is_active')">Active</radio>
                            </div>
                            <div class="col-4">
                              <radio name="apd_is_active" v-model="row.apd_is_active" option="N">Inactive</radio>
                            </div>
                          </div>
                        </div>
                      </div>
                    </BoField>

                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12 ">
                        <BoField name="apd_desc_en">
                          <CKEditor name="apd_desc_en" class="form-control" v-model="row.apd_desc_en"></CKEditor>
                        </BoField>
                        <LabelError :name="'apd_desc_en'"></LabelError>
                      </div>
                      <div class="col-md-12 ">
                        <BoField name="apd_desc_id">
                          <CKEditor name="apd_desc_id" class="form-control" v-model="row.apd_desc_id"></CKEditor>
                        </BoField>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 class="card-title m-t-20">SEO</h5>
                <hr>
                <div class="row">
                  <div class="col-md-6">
                    <BoField name="apd_meta_title_en" :attr="{maxlength:254,minlength:3}"
                      v-model="row.apd_meta_title_en"></BoField>
                  </div>
                  <div class="col-md-6">
                    <BoField name="apd_meta_title_id" v-model="row.apd_meta_title_id"></BoField>
                  </div>
                  <div class="col-md-6">
                    <BoField name="apd_meta_keyword_en">
                      <TagsInput name="apd_meta_keyword_en" v-model="row.apd_meta_keyword_en"
                        :attr="validation('apd_meta_keyword_en')">
                      </TagsInput>
                    </BoField>
                  </div>
                  <div class="col-md-6">
                    <BoField name="apd_meta_keyword_id">
                      <TagsInput name="apd_meta_keyword_id" v-model="row.apd_meta_keyword_id"
                        :attr="validation('apd_meta_keyword_id')">
                      </TagsInput>
                    </BoField>
                  </div>
                  <div class="col-md-6">
                    <BoField name="apd_meta_desc_en">
                      <textarea v-model="row.apd_meta_desc_en" :attr="{maxlength:500,minlength:10}"
                        title="input meta description (EN)" v-bind="validation('apd_meta_desc_en')" rows="4"
                        class="form-control"></textarea>
                      <LabelError :name="'apd_meta_desc_en'"></LabelError>
                    </BoField>
                  </div>
                  <div class="col-md-6">
                    <BoField name="apd_meta_desc_id">
                      <textarea v-model="row.apd_meta_desc_id" rows="4" class="form-control"></textarea>
                    </BoField>
                  </div>
                </div>
              </div>
              <div class="form-actions m-t-20 text-right">
                <a @click="preview" style="color:#fff;" class="btn btn-rounded btn-success mr-2">Preview Product</a>
                <button type="submit" class="btn btn-rounded btn-info btn-loading">Save Product</button>
              </div>
            </div>
          </VForm>
        </div>
      </div>
      <!--  -->

      <!-- Modal add Body -->
      <div id="add_varietals" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_varietals"
        aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="add_varietals">Add Varietals</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <VForm @resp="addVarietals">
              <div class="modal-body">
                <div class="row">
                  <div class="col-12 modal-info"></div>
                  <div class="col-md-12">
                    <BoField name="mpv_name_en" mandatory
                      :attr="{placeholder:'e.g. Varietals',required:'required',minlength:3,maxlength:100}"
                      v-model="row2.mpv_name_en"></BoField>
                    <BoField name="mpv_name_id" mandatory
                      :attr="{placeholder:'e.g. Varietals',required:'required',minlength:3,maxlength:100}"
                      v-model="row2.mpv_name_id"></BoField>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-rounded  btn-info btn-loading">Submit</button>
              </div>
            </VForm>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>

      <!-- Modal add Body -->
      <div id="add_body" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_body" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="add_body">Add Body Size</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <VForm @resp="addBody">
              <div class="modal-body">
                <div class="row">
                  <div class="col-12 modal-info"></div>
                  <div class="col-md-12">
                    <BoField name="mbps_name_en" mandatory
                      :attr="{placeholder:'e.g. Full Body',required:'required',minlength:3,maxlength:100}"
                      v-model="row2.mbps_name_en"></BoField>
                  </div>
                  <div class="col-md-12">
                    <BoField name="mbps_name_id" :attr="{placeholder:'e.g. Full Body',minlength:3,maxlength:100}"
                      v-model="row2.mbps_name_id"></BoField>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-rounded  btn-info btn-loading">Submit</button>
              </div>
            </VForm>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- Modal add Scale -->
      <div id="add_scale" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_scale" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="add_scale">Add Scale</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <VForm @resp="addScale">
              <div class="modal-body">
                <div class="row">
                  <div class="col-12 modal-info"></div>
                  <div class="col-md-12">
                    <BoField name="mds_name_en" mandatory
                      :attr="{placeholder:'e.g. Sweet',required:'required',minlength:3,maxlength:100}"
                      v-model="row2.mds_name_en"></BoField>
                  </div>
                  <div class="col-md-12">
                    <BoField name="mds_name_id" :attr="{placeholder:'e.g. Sweet',minlength:3,maxlength:100}"
                      v-model="row2.mds_name_id"></BoField>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-rounded  btn-info btn-loading">Submit</button>
              </div>
            </VForm>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>

      <!-- Modal add Region -->
      <div id="add_region" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_region" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="add_region">Add Region</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <VForm @resp="addRegion">
              <div class="modal-body">
                <div class="row">
                  <div class="col-12 modal-info"></div>
                  <div class="col-md-6">
                    <BoField name="mr_name_en" mandatory
                      :attr="{placeholder:'e.g. Indonesia',required:'required',minlength:3,maxlength:100}"
                      v-model="row2.mr_name_en"></BoField>
                  </div>
                  <div class="col-md-6">
                    <BoField name="mr_name_id" :attr="{placeholder:'e.g. Indonesia',minlength:3,maxlength:100}"
                      v-model="row2.mr_name_id"></BoField>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-rounded  btn-info btn-loading">Submit</button>
              </div>
            </VForm>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- Modal add Country -->
      <div id="add_country" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_country" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="add_country">Add Country</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <VForm @resp="addCounry">
              <div class="modal-body">
                <div class="row">
                  <div class="col-12 modal-info"></div>
                  <div class="col-md-6">
                    <BoField name="mc_name_en" mandatory
                      :attr="{placeholder:'e.g. Indonesia',required:'required',minlength:3,maxlength:100}"
                      v-model="row2.mc_name_en"></BoField>
                  </div>
                  <div class="col-md-6">
                    <BoField name="mc_name_id" :attr="{placeholder:'e.g. Indonesia',minlength:3,maxlength:100}"
                      v-model="row2.mc_name_id"></BoField>
                  </div>
                  <div class="col-md-6">
                    <BoField name="mc_capital_city_en" mandatory
                      :attr="{placeholder:'e.g. Jakarta',required:'required',minlength:3,maxlength:100}"
                      v-model="row2.mc_capital_city_en"></BoField>
                  </div>
                  <div class="col-md-6">
                    <BoField name="mc_capital_city_id" :attr="{placeholder:'e.g. Jakarta',minlength:3,maxlength:100}"
                      v-model="row2.mc_capital_city_id"></BoField>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-rounded  btn-info btn-loading">Submit</button>
              </div>
            </VForm>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>

      <!-- Preview -->
      <div id="preview_product" class="modal" tabindex="-1" role="dialog" aria-labelledby="preview_product"
        aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="prod_cat">Detail Product</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-5">
                  <img v-if="row.apd_image" class="img-responsive" :src="uploader(row.apd_image)" />
                  <img v-else class="img-responsive" src="http://placehold.it/500x500" />
                </div>
                <div class="col-md-7">
                  <div class="row">
                    <div class="col-md-10">
                      <h4>{{row.apd_name}}</h4>
                      <p class="mb-1">
                        {{(row.text_brand||'')}} {{row.apd_grape?'/':''}} {{(row.apd_grape||'')}} {{row.apd_avb?'/':''}}
                        {{row.apd_avb ? row.apd_avb+'% ABV' : ''}} {{row.text_country?'/':''}}
                        {{(row.text_country||'')}}
                      </p>
                      <h4 class="mb-3" style="font-weight: bold;"><strong> Rp {{(row.apd_price||'').format()}}</strong>
                      </h4>
                      <a class="btn_buy" href="#">Check Availability</a>
                    </div>
                    <hr>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-2">
                  <p class="mt-2 mb-2" style="font-weight: bold;">
                    Product Detail
                  </p>
                  <div class="table-responsive">
                    <table class="table product-overview">
                      <tbody>
                        <tr v-if="row.text_brand">
                          <th>BRAND</th>
                          <td>{{row.text_brand}}</td>
                        </tr>
                        <tr v-if="row.apd_year">
                          <th>YEAR</th>
                          <td>{{row.apd_year}}</td>
                        </tr>
                        <tr v-if="row.apd_grape">
                          <th>GRAPE</th>
                          <td>{{row.apd_grape}}</td>
                        </tr>
                        <tr v-if="row.apd_avb">
                          <th>ABV</th>
                          <td>{{row.apd_avb ? row.apd_avb+'%' : ''}}
                          </td>
                        </tr>
                        <tr v-if="row.text_scale">
                          <th>SWEET-DRY SCALE</th>
                          <td>
                            {{row.text_scale}}
                          </td>
                        </tr>
                        <tr v-if="row.text_body">
                          <th>Body</th>
                          <td>{{row.text_body}}</td>
                        </tr>
                        <tr v-if="row.apd_pairing">
                          <th>PAIRING</th>
                          <td>
                            {{row.apd_pairing}}
                          </td>
                        </tr>
                        <tr v-if="row.apd_note">
                          <th>TASTING NOTES</th>
                          <td>
                            {{row.apd_note}}
                          </td>
                        </tr>
                        <tr v-if="row.text_country">
                          <th>COUNTRY</th>
                          <td>
                            {{row.text_country}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <p class="mb-2" style="font-weight: bold;">
                    Product Description
                  </p>
                  <dev v-html="row.apd_desc_en"></dev>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>